// import logo from './logo.svg';
import './App.css';
import GetStartedWindow from './components/GetStartedWindow';
import MainWindow from "./components/MainWindow";
import { Routes, Route } from 'react-router-dom';
import TaxReassessmentWindow from './components/TaxReassessmentWindow';
import PaymentWindow from './components/PaymentWindow';

function App() {
  return (
    <div className="App">
      {/*<header className="App-header">*/}
      {/*  <p>*/}
      {/*    Demo of property tax reassessment.*/}
      {/*  </p>*/}
      {/*</header>*/}
      <Routes>
        <Route path="/" element={<MainWindow />} />
        <Route path="/get-started" element={<GetStartedWindow />} />
        <Route path="/tax-reassessment" element={<TaxReassessmentWindow />} />
        <Route path="/payment" element={<PaymentWindow />} />
      </Routes>
    </div>

  );
}

export default App;
