import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import './GetStartedWindow.css';
import { formatFloatValue } from "../helpers";

const GetStartedWindow = () => {
  const location = useLocation();
  const [loginType, setLoginType] = useState(location.state.loginType);
  const [targetProperty, setTargetProperty] = useState(location.state.propertyDetails);
  const [resultMetadata, setResultMetadata] = useState(location.state.resultMetadata);

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const [estimateResult, setEstimateResult] = useState(null);

  const [assessedValue, setAssessedValue] = useState(targetProperty.taxAssessedValue * 1.02);

  useEffect(() => {
    setLoginType(location.state.loginType);
    setTargetProperty(location.state.propertyDetails);
    setResultMetadata(location.state.resultMetadata);
  }, [location]);

  useEffect(() => {
    setAssessedValue(targetProperty.taxAssessedValue * 1.02);
  }, [targetProperty]);

  // This function returns true if system assessed value is LESS THAN the provided assessed value.
  async function getEstimateResult(assessedValue) {
    setErrorMessage(null);
    setEstimateResult(null);

    try {
      const response = await fetch('/api/tax-reassessment/post-assessed-value', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'assessedValue': assessedValue, 'resultMetadata': resultMetadata})
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        setErrorMessage('Request failed with status code ' + response.status);
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
      // Return an error message or handle the error as needed
      return 'An error occurred during the tax reassessment.';
    }
  }

  const handleShowResultButtonClick = async (e) => {
    e.preventDefault();
    
    // Process the input value and generate the result
    const processedResult = await getEstimateResult(assessedValue);
    // console.log("result ", processedResult)
    if (processedResult.error) {
      // show the error
      setErrorMessage(processedResult.error);
    } else {
      setEstimateResult(processedResult.estimate_result);
      setShowEstimateResult(true);
    }
  };

  // console.log("estimateResult", estimateResult)

  const handleInputChange = (e) => {
    setAssessedValue(e.target.value);
  };

  const handleGoToPaymentButtonClick = () => {
    // console.log("get_started - targetProperty", targetProperty)
    // console.log("get_started - assessedValue", assessedValue)

    navigate('/payment');
  };

  // TODO: remove this button.
  const handleGoToRefButtonClick = () => {
    // console.log("get_started - targetProperty", targetProperty)
    // console.log("get_started - assessedValue", assessedValue)

    navigate('/tax-reassessment', {
      state: {
        loginType: loginType,
        targetProperty: targetProperty, 
        assessedValue: assessedValue,
        resultMetadata: resultMetadata
      }
    });
  };

  const [showEstimateResult, setShowEstimateResult] = useState(false);

  return (
    <div className="container">
      <h3>According to our record, the tax value of your property is ${formatFloatValue(targetProperty.taxAssessedValue)} in year {targetProperty.taxAssessedYear}. </h3>
      <h3>If you have got the assessed value for this year, please provide it here.</h3>
      <h3>Or we can use the assessed value from the previous year with a 2% increase.</h3>
      <input
        type="text"
        value={assessedValue}
        onChange={handleInputChange}
        placeholder="Enter value..."
        className="input-box"
      />
      <button className="button" onClick={handleShowResultButtonClick}>Show Result</button>
      { showEstimateResult && estimateResult !== null &&
        <div>
          {
            estimateResult
              ? <h3>Congratulations! We believe you can get some tax back.</h3>
              : <h3>Unfortunately, we do not think you could get tax back this year.</h3>
          }
        </div>
      }
      <div>
        <button className="button" onClick={handleGoToPaymentButtonClick}>Go To Payment (WIP)</button>
      </div>
      {/* TODO: remove this button. */}
      <div>
        <button className="button" onClick={handleGoToRefButtonClick}>Go To Ref</button>
      </div>
    </div>
  );
}

export default GetStartedWindow;