import React, {useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './MainWindow.css';
import TargetPropertyTable from './TargetPropertyTable';
import { formatFloatValue } from "../helpers";

const MainWindow = () => {
  const LOGIN_TYPE = 'business';
  // const LOGIN_TYPE = 'consumer';

  const navigate = useNavigate();

  const [address, setAddress] = useState('');
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [propertyDetailsMetadata, setPropertyDetailsMetadata] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const addressInputRef = useRef(null);

  const [bathNotEmpty, setBathNotEmpty] = useState(null);
  const [bedNotEmpty, setBedNotEmpty] = useState(null);
  const [livingSizeNotEmpty, setLivingSizeNotEmpty] = useState(null);
  const [lotSizeNotEmpty, setlotSizeNotEmpty] = useState(null);

  const [propertyDetailsComprehensive, setPropertyDetailsComprehensive] = useState(null);

  const supportedHomeTypes = ['SINGLE_FAMILY', 'TOWNHOUSE'];
  const [isHomeTypeSupported, setIsHomeTypeSupported] = useState(null);
  
  const [recentlySoldInfo, setRecentlySoldInfo] = useState(null);
  // const [isRecentlySold, setIsRecentlySold] = useState(null);

  const [isPropertySupported, setIsPropertySupported] = useState(null);

  useEffect(() => {
    if (propertyDetails) {
      setPropertyDetailsComprehensive(
        propertyDetails.bathrooms && propertyDetails.bedrooms && 
        propertyDetails.living_area_sqft && propertyDetails.lot_area_sqft
      )

      setBathNotEmpty(propertyDetails.bathrooms);
      setBedNotEmpty(propertyDetails.bedrooms);
      setLivingSizeNotEmpty(propertyDetails.living_area_sqft);
      setlotSizeNotEmpty(propertyDetails.lot_area_sqft);

      setIsHomeTypeSupported(supportedHomeTypes.includes(propertyDetails.homeType.toUpperCase()));

      const recentlySold = propertyDetails.recentlySold;
      // console.log("recentlySold", recentlySold)
  
      if (recentlySold.isRecentlySold) {
        setRecentlySoldInfo(
          {
            dateSoldStr: recentlySold.lastSoldDate,
            price: recentlySold.lastSoldPrice
          }
        );
      } else {
        setRecentlySoldInfo(null);
      }
    }
  }, [propertyDetails]);

  useEffect(() => {
    setIsPropertySupported(isHomeTypeSupported === true && (LOGIN_TYPE === 'business' || recentlySoldInfo === null));
  }, [isHomeTypeSupported, recentlySoldInfo]);

  useEffect(() => {
    if (addressInputRef.current && window.google.maps) {
      // console.log('hooking up autocomplete of Google Maps to this input');
      const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);

      // Handle the address selection
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let streetNumber = '';
        // Extract and use the address components from the place object
        // console.log(place);
        if (!place.address_components) {
          console.error('No address components found');
          return;
        } else {
          setAddress(place.formatted_address);
        }
      });
    }

    // Clean up the script element
    return () => {
      // document.body.removeChild(script);
    };
  }, [addressInputRef.current]);

  async function postPropertyAddress(address) {
    // First clean up the previous result
    setPropertyDetails(null);
    setPropertyDetailsMetadata('');
    setErrorMessage(null);
    setPropertyDetailsComprehensive(null);
    setBathNotEmpty(null);
    setBedNotEmpty(null);
    setLivingSizeNotEmpty(null);
    setlotSizeNotEmpty(null);

    try {
      const response = await fetch('/api/tax-reassessment/post_address', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'address': address})
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        setErrorMessage('Request failed with status code ' + response.status);
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
      // Return an error message or handle the error as needed
      return 'An error occurred during the tax reassessment.';
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Process the input value and generate the result
    const processedResult = await postPropertyAddress(address);
    // console.log("result ", processedResult)
    if (processedResult.error) {
      // show the error
      setErrorMessage(processedResult.error)
    } else {
      setPropertyDetails(processedResult.target_property);
      setPropertyDetailsMetadata(processedResult.target_property_details_metadata);
    }
  };

  const handleInputChange = (e) => {
    setAddress(e.target.value);
    // console.log('after change, input value is ', e.target.value);
  };

  // console.log("propertyDetailsMetadata", propertyDetailsMetadata);
  // console.log("propertyDetails", propertyDetails);

  async function postPropertyDetails() {
    // First clean up the previous result
    setErrorMessage(null);

    try {
      const response = await fetch('/api/tax-reassessment/load_result', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'propertyDetailsMetadata': propertyDetailsMetadata,
          'bathrooms': bathNotEmpty,
          'bedrooms': bedNotEmpty,
          'livingSize': livingSizeNotEmpty,
          'lotSize': lotSizeNotEmpty
        })
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        setErrorMessage('Request failed with status code ' + response.status);
        throw new Error('Request failed');
      }
    } catch (error) {
      console.error('Error:', error);
      // Return an error message or handle the error as needed
      return 'An error occurred during the tax reassessment.';
    }
  }

  const handleYesContinueButtonClick = async () => {
    if (isPropertySupported) {
      const processedResult = await postPropertyDetails();
      // console.log("processedResult", processedResult)
      if (processedResult.error) {
        // show the error
        setErrorMessage(processedResult.error)
      } else {
        LOGIN_TYPE === 'business'
          ? navigate('/tax-reassessment', {
              state: {
                loginType: LOGIN_TYPE,
                targetProperty: processedResult.target_property, 
                assessedValue: null,
                resultMetadata: processedResult.result_metadata
              }
            })
          : processedResult.empty_result
              ? setErrorMessage("No enough reference properties found for re-assessing property value.")
              : navigate('/get-started', {
                state: {
                  loginType: LOGIN_TYPE,
                  propertyDetails: processedResult.target_property,
                  resultMetadata: processedResult.result_metadata
                }
              });
      }
    } else {
      if (!isHomeTypeSupported) {
        setErrorMessage("Sorry, only simgle family houses and townhouses are supported at this time.")
      } else if (LOGIN_TYPE !== 'business' && recentlySoldInfo !== null) {
        setErrorMessage(
          `According to our records, this property was sold on ${recentlySoldInfo.dateSoldStr} for ${formatFloatValue(recentlySoldInfo.price)}.
          Property tax reassessment does not apply to properties sold in the past 12 months.`
        )
      } else {
        setErrorMessage("Sorry, you property is not supported at this time.")
      }
        
    }
  };

  const handleOKButtonClick = () => {
    window.location.reload();
  };

  const handleBathNotEmptyInputChange = (e) => {
    const parsedValue = parseInt(e.target.value, 10);
    setBathNotEmpty(parsedValue);
  };

  const handleBedNotEmptyInputChange = (e) => {
    const parsedValue = parseInt(e.target.value, 10);
    setBedNotEmpty(parsedValue);
  };

  const handleLivingSizeNotEmptyInputChange = (e) => {
    const parsedValue = parseInt(e.target.value, 10);
    setLivingSizeNotEmpty(parsedValue);
  };

  const handleLotSizeNotEmptyInputChange = (e) => {
    const parsedValue = parseInt(e.target.value, 10);
    setlotSizeNotEmpty(parsedValue);
  };

  // console.log("bathNotEmpty", bathNotEmpty);
  // console.log("bedNotEmpty", bedNotEmpty);
  // console.log("livingSizeNotEmpty", livingSizeNotEmpty);
  // console.log("lotSizeNotEmpty", lotSizeNotEmpty);

  return (
    <div className="MainWindow">
      {/* <form onSubmit={handleSubmit}> */}
      <div>
        <input
          type="text"
          value={address}
          onChange={handleInputChange}
          placeholder="Enter value..."
          className="input-box"
          ref={addressInputRef}
        />
        <button type="submit" className="submit-button" onClick={handleSubmit}>Submit</button>
      </div>
      {/* </form> */}
      <div>
        {propertyDetails && 
          <div>
            <h3>We found your property in our database.</h3>
            <TargetPropertyTable propertyDetails={propertyDetails} />
            {/* { (bathNotEmpty !== null && bedNotEmpty !== null && livingSizeNotEmpty !== null && lotSizeNotEmpty !== null) */}
            {propertyDetailsComprehensive  
              ? (
                  <div>
                    <h3>Does everything look correct to you?</h3>  
                    <div className="confirm-button-grid">
                      <button className="confirm-button">No</button>
                      <button className="confirm-button" onClick={handleYesContinueButtonClick}>Yes</button>
                    </div>
                  </div>
                )
              : (
                  <div>
                    <h3>Please provide the missing informatin before continue.</h3>
                    {!propertyDetails.bathrooms && 
                      <div>
                        <h4>Bathrooms</h4>
                        <input
                          type="text"
                          value={bathNotEmpty || ''}
                          onChange={handleBathNotEmptyInputChange}
                          placeholder="Enter number of Bathrooms..."
                          className="input-box"
                        />
                      </div>
                    }
                    {!propertyDetails.bedrooms && 
                      <div>
                        <h4>Bedrooms</h4>
                        <input
                          type="text"
                          value={bedNotEmpty || ''}
                          onChange={handleBedNotEmptyInputChange}
                          placeholder="Enter number of Bedrooms..."
                          className="input-box"
                        />
                      </div>
                    }
                    {!propertyDetails.living_area_sqft && 
                      <div>
                        <h4>Living area (sqft)</h4>
                        <input
                          type="text"
                          value={livingSizeNotEmpty || ''}
                          onChange={handleLivingSizeNotEmptyInputChange}
                          placeholder="Enter Living area size in sqft..."
                          className="input-box"
                        />
                      </div>
                    }
                    {!propertyDetails.lot_area_sqft && 
                      <div>
                        <h4>Lot size (sqft)</h4>
                        <input
                          type="text"
                          value={lotSizeNotEmpty || ''}
                          onChange={handleLotSizeNotEmptyInputChange}
                          placeholder="Enter Lot size in sqft..."
                          className="input-box"
                        />
                      </div>
                    }
                    <button 
                      className="confirm-button" 
                      disabled={!bathNotEmpty || !bedNotEmpty || !livingSizeNotEmpty || !lotSizeNotEmpty} 
                      onClick={handleYesContinueButtonClick}
                    >Continue</button>
                  </div>
                )
            }
          </div>
        }
        {errorMessage &&
          <div>
            <div className='error-message'>{errorMessage}</div>
            <button onClick={handleOKButtonClick}>OK</button>
          </div>
        }
      </div>
    </div>
  );
}

export default MainWindow;