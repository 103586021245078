import React from "react";
import './AppealTemplate.css';
import { formatFloatValue } from '../helpers';

function AppealTemplate({ target_property, referenceProperties }) {
  const calculatePricePerSquareFoot = (price, area) => {
    // console.log("price ", price, ", area ", area, ", ret: ", (price / area).toFixed(2));
    return (price / area);
  };

  const calculateAveragePricePerSquareFoot = (prices) => {
    const sum = prices.reduce((total, price) => total + price, 0);
    // console.log("Prices: ", prices, ', ret: ', (sum / prices.length).toFixed(2))
    return (sum / prices.length);
  };

  const calculateEstimatedHouseValue = (lotValue, livingAreaValue) => {
    // console.log(lotValue, livingAreaValue, ((lotValue + livingAreaValue) / 2).toFixed(2))
    // return ((lotValue + livingAreaValue) / 2);
    return livingAreaValue;
  };

  const lotPricePerSqft = referenceProperties.map((property) =>
    calculatePricePerSquareFoot(property.lastSoldPrice, property.lotSize)
  );

  const livingAreaPricePerSqft = referenceProperties.map((property) =>
    calculatePricePerSquareFoot(property.lastSoldPrice, property.livingAreaValue)
  );

  const averageLotPricePerSqft = calculateAveragePricePerSquareFoot(
    lotPricePerSqft
  );

  const averageLivingAreaPricePerSqft = calculateAveragePricePerSquareFoot(
    livingAreaPricePerSqft
  );

  const estimatedLotValue = target_property.lot_area_sqft * averageLotPricePerSqft;

  const estimatedLivingAreaValue = target_property.living_area_sqft * averageLivingAreaPricePerSqft;

  const estimatedHouseValue = calculateEstimatedHouseValue(
    estimatedLotValue,
    estimatedLivingAreaValue
  );

  return (
    <div>
      <div><h2 className="appeal-header">Appeal Letter</h2></div>
      <div className="appeal-letter">
        Dear Sir or Madam,
        <br />
        <br />
        I hope this letter finds you well. As per your request, I am writing to
        provide an estimate for the market value of a target property. This
        estimation is based on a comparative analysis using data from three
        other properties that have recently been sold.
        <br />
        <br />
        {
          referenceProperties.map((property, index) => (
            <div key={index}>
              House {index+1}: {property.streetAddress}, {property.city}, {property.state} {property.zipcode}.
            </div>
          ))
        }
        <br />
        The properties used for comparison, hereinafter referred to as House 1,
        House 2, and House 3, have the following details:
        <br />
        <br />
        {
          referenceProperties.map((property, index) => (
            <div key={index}>
              House {index+1} was sold for ${property.lastSoldPrice.toLocaleString()} with
              {/* a lot size of{' '}{property.lotSize.toLocaleString()} square feet and  */}
              {' '}a living area of{' '}{property.livingAreaValue.toLocaleString()} square feet.
            </div>
          ))
        }
        <br />
        Our methodology will be to calculate the average price per square foot for
        {/* both the lot and  */}
        {' '}the living area across these three properties. We will then use these averages 
        to estimate the value of the target property, which has 
        {/* a lot size of {target_property.lot_area_sqft.toLocaleString()} square feet and  */}
        {' '}a living area of {target_property.living_area_sqft.toLocaleString()} square feet.
        <br />
        <br />
        <b>Step 1:</b> Calculate the price per square foot for the lot and living area
        for each house.
        <br />
        {
          referenceProperties.map((property, index) => (
            <div key={index}>
              <br />
              <i>House {index+1}</i>:
              {/* <br />Lot price per square foot = ${property.lastSoldPrice.toLocaleString()} / {property.lotSize.toLocaleString()} sqft = ~${formatFloatValue(property.lotPricePerSqft)}; */}
              <br />Living area price per sqft = ${property.lastSoldPrice.toLocaleString()} / {property.livingAreaValue.toLocaleString()} sqft = ~${formatFloatValue(property.livingPricePerSqft)}.
            </div>
          ))
        }
        <br />
        <br />
        <b>Step 2:</b> Calculate the average price per square foot across the three houses.
        <br />
        {/* Average lot price per sqft = (${formatFloatValue(referenceProperties[0].lotPricePerSqft)} + ${formatFloatValue(referenceProperties[1].lotPricePerSqft)} + ${formatFloatValue(referenceProperties[2].lotPricePerSqft)}) / 3 = ~${formatFloatValue(averageLotPricePerSqft)}.
        <br /> */}
        Average living area price per sqft = (${formatFloatValue(referenceProperties[0].livingPricePerSqft)} + ${formatFloatValue(referenceProperties[1].livingPricePerSqft)} + ${formatFloatValue(referenceProperties[2].livingPricePerSqft)}) / 3 = ~${formatFloatValue(averageLivingAreaPricePerSqft)}.
        <br />
        <br />
        <b>Step 3:</b> Use the average price per square foot to estimate the value of the target house.
        <br />
        {/* Estimated lot value = {target_property.lot_area_sqft.toLocaleString()} sqft * ${formatFloatValue(averageLotPricePerSqft)} per sqft = ~${formatFloatValue(estimatedLotValue)}.
        <br /> */}
        Estimated living area value = {target_property.living_area_sqft.toLocaleString()} sqft * ${formatFloatValue(averageLivingAreaPricePerSqft)} per sqft = ~${formatFloatValue(estimatedLivingAreaValue)}.
        <br />
        <br />
        {/* <b>Step 4:</b> Calculate the mean of the estimated lot value and estimated living area value to derive the estimated total value of the target house.
        <br />
        Estimated house value = (${formatFloatValue(estimatedLotValue)} + ${formatFloatValue(estimatedLivingAreaValue)}) / 2 = ~${formatFloatValue(estimatedHouseValue)}.
        <br /> */}
        Based on the comparative analysis, the estimated market value of the target property is approximately ${formatFloatValue(estimatedHouseValue)}.
        <br />
        <br />
        I believe that this estimation provides a useful baseline for your considerations regarding the target property. Should you require further assistance or have any additional questions, please do not hesitate to reach out. I would be more than happy to help provide more information or clarification on this matter.
        <br />
        <br />
        Kind Regards,
        <br />
        <br />
        <br />
        [Your Name]
      </div>
    </div>
  );
}

export default AppealTemplate;