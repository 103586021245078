import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import "./TaxReassessmentWindow.css";
import AppealTemplate from "./AppealTemplate";
import { formatFloatValue } from "../helpers";
import TargetPropertyTable from "./TargetPropertyTable";

const TaxReassessmentWindow = () => {
  const maxNumberOfSelectedProperties = 3;
  
  const location = useLocation();

  // console.log("location", location);

  const [targetProperty, setTargetProperty] = useState(location.state.targetProperty);
  const [assessedValue, setAssessedValue] = useState(location.state.assessedValue);
  const [resultMetadata, setResultMetadata] = useState(location.state.resultMetadata);

  // console.log("assessedValue", assessedValue)

  const [errorMessage, setErrorMessage] = useState(null);
  const [referenceProperties, setReferenceProperties] = useState(null);

  const [referenceTableData, setReferenceTableData] = useState(null);
  // const [systemSeletedZpids, setSystemSeletedZpids] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [selectedZpids, setSelectedZpids] = useState([]);

  // system selected properties
  const [systemSelectedPPSMin, setSystemSelectedPPSMin] = useState([]);
  // const [systemSelectedPPSMax, setSystemSelectedPPSMax] = useState([]);
  const [systemSelectedDistance, setSystemSelectedDistance] = useState([]);

  const [showSystemSelectButtonAndPrice, setShowSystemSelectButtonAndPrice] = useState(false);

  const [showSystemEstPrice, setShowSystemEstPrice] = useState(false);

  const navigate = useNavigate();

  const handleGoToMainPageButtonClick = () => {
    navigate('/');
  };

  useEffect(() => {
    setShowSystemEstPrice(showSystemSelectButtonAndPrice);
  }, [showSystemSelectButtonAndPrice]);


  const getReferenceProperties = async () => {
    setErrorMessage(null);
    setReferenceProperties(null);
    setSystemSelectedPPSMin([]);
    // setSystemSelectedPPSMax([]);
    setSystemSelectedDistance([]);
    setShowSystemSelectButtonAndPrice(false);
    setShowSystemEstPrice(false);

    try {
      const response = await fetch('/api/tax-reassessment/reference-properties', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({'resultMetadata': resultMetadata})
      });

      const data = await response.json();
      setReferenceProperties(data.all_references);
      setSystemSelectedPPSMin(data.living_pps_min.properties);
      // setSystemSelectedPPSMax(data.living_pps_max.properties);
      setSystemSelectedDistance(data.distance.properties);

      if (data.distance.properties.length === maxNumberOfSelectedProperties) {
        setShowSystemSelectButtonAndPrice(true);
      }

    } catch (error) {
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    setTargetProperty(location.state.targetProperty);
    setAssessedValue(location.state.assessedValue);
    setResultMetadata(location.state.resultMetadata);
    getReferenceProperties();
  }, [location]);

  useEffect(() => {
    if (referenceProperties !== null) {
      setReferenceTableData(referenceProperties.map((data) => convertToTableData(data)));
      // setSystemSeletedZpids(referenceProperties.slice(0,3).map((p) => { return p.zpid }));
    }
  }, [referenceProperties]);

  useEffect(() => {
    setTableData(referenceTableData);
  }, [referenceTableData]);

  useEffect(() => {
    setSelectedZpids(systemSelectedDistance);
  }, [systemSelectedDistance]);


  // console.log("referenceProperties", referenceProperties)
  // console.log("systemSeletedZpids", systemSeletedZpids)

  function convertToTableData(data) {
    return {
      zpid: data.zpid,
      address: `${data.streetAddress}, ${data.city}, ${data.state} ${data.zipcode}`,
      bedsBaths: `${data.bedrooms} / ${data.bathrooms}`,
      livingSize: data.livingAreaValue,
      lotSize: data.lotSize,
      lastSoldPrice: data.lastSoldPrice,
      lastSoldDate: data.dateSold,
      schoolMatch: data.match,
      distance: data.distance,
      ppsLiving: data.livingPricePerSqft,
      ppsLot: data.lotPricePerSqft
    };
  }

  const columns = [
    {label: "", accessor: "select", useFloatFormat: false, showInTable: true, sortable: false}, 
    {label: "Zpid", accessor: "zpid", useFloatFormat: false, showInTable: false, sortable: true}, 
    {label: "Address", accessor: "address", useFloatFormat: false, showInTable: true, sortable: false}, 
    {label: "Beds / Baths", accessor: "bedsBaths", useFloatFormat: false, showInTable: true, sortable: true}, 
    {label: "Living Size (sqft)", accessor: "livingSize", useFloatFormat: false, showInTable: true, sortable: true}, 
    {label: "Lot Size (sqft)", accessor: "lotSize", useFloatFormat: false, showInTable: true, sortable: true}, 
    {label: "Last Sold Price ($)", accessor: "lastSoldPrice", useFloatFormat: true, showInTable: true, sortable: true}, 
    {label: "Date Sold", accessor: "lastSoldDate", useFloatFormat: false, showInTable: true, sortable: true}, 
    {label: "Schools Matches", accessor: "schoolMatch", useFloatFormat: false, showInTable: true, sortable: true}, 
    {label: "Distance (miles)", accessor: "distance", useFloatFormat: true, showInTable: true, sortable: true}, 
    {label: "$/sqft Living", accessor: "ppsLiving", useFloatFormat: true, showInTable: true, sortable: true}, 
    {label: "$/sqft Lot", accessor: "ppsLot", useFloatFormat: true, showInTable: false, sortable: true}
  ];

  const [order, setOrder] = useState("asc");
  const [sortField, setSortField] = useState("");

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;

        return (
          a[sortField] < b[sortField]
            ? sortOrder === "asc" ? -1 : 1
            : sortOrder === "asc" ? 1 : -1
        );
      });
      setTableData(sorted);
    }
   };

  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const [showEstPrice, setShowEstPrice] = useState(false);
  const [showAppealLetter, setShowAppealLetter] = useState(false);
  const [selectedRef, setSelectedRef] = useState([]);

  useEffect(() => {
    setShowEstPrice(false);
    setShowAppealLetter(false);
    referenceProperties && setSelectedRef(referenceProperties.filter((ref) => selectedZpids.includes(ref.zpid)));
  }, [selectedZpids]);

  const handleRowSelection = (zpid) => {
    if (selectedZpids.includes(zpid)) {
      setSelectedZpids(selectedZpids.filter((id) => id !== zpid));
    } else {
      setSelectedZpids([...selectedZpids, zpid]);
    }
    setShowSystemEstPrice(false);
  };

  const calculateEstimateMarketPrice = () => {
    let sum = 0;
    
    selectedRef.forEach(ref => sum += ref.estPrice);

    return selectedRef.length === 0
      ? 0
      : sum / selectedRef.length;
  }

  const ref = useRef(null);

  const handleShowExtPriceButtonClick = () => {
    setShowEstPrice(true);
  };

  const handleShowAppealLetterButtonClick = () => {
    setShowAppealLetter(true);
  };

  const [showAppealLetterButton, setShowAppealLetterButton] = useState(false);

  useEffect(() => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  }, [selectedRef, showEstPrice, showAppealLetter, showAppealLetterButton]);

  const disableCheckbox = (zpid) => {
    return selectedZpids.length >= maxNumberOfSelectedProperties && !selectedZpids.includes(zpid)
  };

  // console.log("referenceProperties", referenceProperties)
  // console.log("tableData", tableData)

  const handlePPSMinButtonClick = () => {
    setSelectedZpids(systemSelectedPPSMin);
    setShowSystemEstPrice(true);
  };

  // const handlePPSMaxButtonClick = () => {
  //   setSelectedZpids(systemSelectedPPSMax);
  // };

  const handleDistanceButtonClick = () => {
    setSelectedZpids(systemSelectedDistance);
    setShowSystemEstPrice(true);
  };
  
  useEffect(() => {
    setShowAppealLetterButton(showEstPrice || showSystemEstPrice);
  }, [showEstPrice, showSystemEstPrice]);

  const displaySystemSelectedButtonAndPrice = () => {
    return (
      <div>
        <h4>By default, reference properties (highlighted) are selected based on the distance to your property.</h4>
        <div className="system-selection-button-grid">
          <button
            onClick={handleDistanceButtonClick} 
            className="system-selection-button"
            disabled={selectedZpids === systemSelectedDistance}
          >
              {
                (selectedZpids === systemSelectedDistance)
                  ? '☑ Similar Houses Sorted by Distance'
                  : '☐ Similar Houses Sorted by Distance'
              }
          </button>
          <button
            onClick={handlePPSMinButtonClick} 
            className="system-selection-button"
            disabled={selectedZpids === systemSelectedPPSMin}
          >
            {
              (selectedZpids === systemSelectedPPSMin)
                ? '☑ Similar Houses Sorted by Price'
                : '☐ Similar Houses Sorted by Price'
            }
          </button>
          {/* <button
            onClick={handlePPSMaxButtonClick} 
            className="buttons">
              system selected based on PPS max
          </button> */}
        </div>
        {
          showSystemEstPrice
            ? <h3 className="reassess-price">
                Estimated Property Value based on selected reference: ${formatFloatValue(calculateEstimateMarketPrice())}
              </h3>
            : displayManuallySelectionMessage()
        }
      </div>
    );
  };

  const displayManuallySelectionMessage = () => {
    return (
      <h4>Please select exact 3 reference properties and check the re-assessed value by clicking the "Show Result" button on the bottom.</h4>
    );
  };

  return (
    <div className="container">
      { tableData &&
        <div>
          <div>
            <h1>Your Property</h1>
            <TargetPropertyTable
              propertyDetails={targetProperty}
              userProvidedTaxAssessedValue={assessedValue}
            />
          </div>
          <div className="table-container">
            <div>
              <h1>Properties Similar to Yours</h1>
              {
                showSystemSelectButtonAndPrice
                  ? displaySystemSelectedButtonAndPrice()
                  : displayManuallySelectionMessage()
              }
              <table className="property-table">
                <thead>
                  <tr>
                    {columns.map(({ label, accessor, showInTable, sortable }) => {
                      const icon = sortField === accessor && order === "asc"
                        ? " ▲"
                        : sortField === accessor && order === "desc"
                          ? " ▼"
                          : "";

                      return (
                        showInTable && (
                          sortable
                            ? <th key={accessor} onClick={() => handleSortingChange(accessor)}>{label}<span>{icon}</span></th>
                            : <th key={accessor}>{label}</th>
                        )
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data) => {
                    return (
                      <tr 
                        key={data.zpid} 
                        style={selectedZpids.includes(data.zpid) ? { background: '#55ff55' } : undefined} 
                        onClick={() => !disableCheckbox(data.zpid) && handleRowSelection(data.zpid)}
                      >
                        {columns.map(({ accessor, useFloatFormat, showInTable }) => {
                          const tData = data[accessor] ? data[accessor] : "——";

                          if (accessor === "select") {
                            return (
                              <td key={accessor}>
                                <input
                                  type="checkbox"
                                  checked={selectedZpids.includes(data.zpid)}
                                  disabled={disableCheckbox(data.zpid)}
                                  onChange={() => handleRowSelection(data.zpid)}
                                />
                              </td>
                            );
                          }
                          else {
                            return (
                              showInTable && (useFloatFormat
                              ? <td key={accessor}>{formatFloatValue(tData)}</td>
                              : <td key={accessor}>{tData}</td>)
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div>
              {!showSystemEstPrice &&
                <button
                  onClick={handleShowExtPriceButtonClick} 
                  disabled={selectedZpids.length !== maxNumberOfSelectedProperties} 
                  className="buttons">
                    Show Result
                </button>
              }
              <div ref={ref}>
                {showEstPrice &&
                  <h3 className="reassess-price">
                    Estimated Property Price: ${formatFloatValue(calculateEstimateMarketPrice())}
                  </h3>
                }
              </div>
              <div ref={ref}>
                {showAppealLetterButton &&
                  <button
                    onClick={handleShowAppealLetterButtonClick}
                    className="buttons"
                    disabled={selectedZpids.length !== maxNumberOfSelectedProperties}
                  >
                    Generate Appeal Letter
                  </button>
                }
              </div>
              <div ref={ref}>
                {showAppealLetter &&
                  <div>
                    <AppealTemplate
                      target_property={targetProperty}
                      referenceProperties={selectedRef}
                    />
                    <button
                      onClick={handleGoToMainPageButtonClick}
                      className="buttons"
                    >
                      Back to main page
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default TaxReassessmentWindow;