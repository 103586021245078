import React from "react";
import "./TargetPropertyTable.css";
import { formatFloatValue } from "../helpers";


function TargetPropertyTable({ propertyDetails, userProvidedTaxAssessedValue=null }) {
  const taxAssessedValue = userProvidedTaxAssessedValue || propertyDetails.taxAssessedValue;
  const taxAssessedYear = userProvidedTaxAssessedValue ? "Current" : (propertyDetails.taxAssessedYear || '').toString();
  
  const showable = taxAssessedValue === null ? false : true
  
  const columns = [
    {label: "Zpid", accessor: "zpid", useFloatFormat: false, showInTable: false}, 
    {label: "Parcel Id", accessor: "parcelId", useFloatFormat: false, showInTable: true}, 
    {label: "Address", accessor: "address", useFloatFormat: false, showInTable: true}, 
    {label: "Beds / Baths", accessor: "bedsBaths", useFloatFormat: false, showInTable: true}, 
    {label: "Living Size (sqft)", accessor: "livingSize", useFloatFormat: false, showInTable: true}, 
    {label: "Lot Size (sqft)", accessor: "lotSize", useFloatFormat: false, showInTable: true}, 
    {label: "Tax Assessed Value ($)", accessor: "taxAssessedValue", useFloatFormat: true, showInTable: showable}, 
    {label: "Tax Assessed Year", accessor: "taxAssessedYear", useFloatFormat: false, showInTable: showable}, 
    {label: "Year Built", accessor: "yearBuilt", useFloatFormat: false, showInTable: true}, 
    {label: "$/sqft Living", accessor: "ppsLiving", useFloatFormat: true, showInTable: showable}, 
    {label: "$/sqft Lot", accessor: "ppsLot", useFloatFormat: true, showInTable: false}
  ];

  const tableData = {
    zpid: propertyDetails.zpid,
    parcelId: propertyDetails.parcelId,
    address: `${propertyDetails.address.streetAddress}, ${propertyDetails.address.city}, ${propertyDetails.address.state} ${propertyDetails.address.zipcode}`,
    bedsBaths: `${propertyDetails.bedrooms} / ${propertyDetails.bathrooms}`,
    livingSize: propertyDetails.living_area_sqft,
    lotSize: propertyDetails.lot_area_sqft,
    taxAssessedValue: taxAssessedValue,
    taxAssessedYear: taxAssessedYear,
    yearBuilt: propertyDetails.yearBuilt,
    ppsLiving: taxAssessedValue / propertyDetails.living_area_sqft,
    ppsLot: taxAssessedValue / propertyDetails.lot_area_sqft
  };

  return (
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {columns.map(({ label, accessor, showInTable }) => {
                return (
                  showInTable && <th key={accessor}>{label}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
                <tr key={tableData.zpid}>
                  {columns.map(({ accessor, useFloatFormat, showInTable }) => {
                    const tData = tableData[accessor] ? tableData[accessor] : "——";
                    return (
                      showInTable && (useFloatFormat
                      ? <td key={accessor}>{formatFloatValue(tData)}</td>
                      : <td key={accessor}>{tData}</td>)
                    );
                  })}
                </tr>
          </tbody>
        </table>
      </div>
  );
}

export default TargetPropertyTable;